.ui.grid .big-box{
    padding: auto !important;
    margin-bottom: 4rem !important;
}

.ui.grid .footer-box{
    padding-top: 2rem !important;
}

.project-title{
    font-size: 2rem !important;
    text-align: center;
    margin-bottom: 1px !important;
}

.grid .ui.styled.accordion .title.scooter{
    background: #B2FEFA;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #0ED2F7, #B2FEFA);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #0ED2F7, #B2FEFA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.grid .ui.styled.accordion .title.honey{
    background: #43C6AC;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F8FFAE, #43C6AC);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F8FFAE, #43C6AC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.grid .ui.styled.accordion .title.tranquil{
    background: #EECDA3;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #EF629F, #EECDA3);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #EF629F, #EECDA3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.grid .ui.styled.accordion .title.trivial{
    background: #e0a3f6;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #e0a3f6, #acc0ff, #7fd7ff, #7ae7ff, #9bf2ec);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #e0a3f6, #acc0ff, #7fd7ff, #7ae7ff, #9bf2ec); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


